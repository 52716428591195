import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Layout, Button } from '../Components';

const Presentation = ({ data }) => (
  <Layout>
    <Container className="my-5">
      <Row>
        <Col md="6" className="my-5">
          <div className="Header__text pr-5 pl-3 pt-3">
            <p>
              Europe Créative est le programme cadre de l’Union européenne pour
              les secteurs culturel créatif et audiovisuel.
            </p>
            <p>
              Avec un budget de 1,46 milliard d’euros, il a pour objectif de
              stimuler l’émergence et le développement de la diversité
              artistique et culturelle, et de renforcer la compétitivité de ces
              secteurs.
            </p>
            <p>Ce programme se compose de deux principaux sous-programmes:</p>
          </div>
          <div className="d-block w-100">
            <Button
              color="primary"
              target="/programme/culture/"
              text="le sous-programme culture"
            />
            <Button
              color="secondary"
              target="/programme/media/"
              text="le sous-programme MEDIA"
            />
          </div>
        </Col>
        <Col md="6" className="p-3 p-md-1">
          <Img
            fluid={data.file.childImageSharp.fluid}
            className="img-fluid mx-auto d-none d-md-block"
          />
        </Col>
      </Row>
    </Container>
    <Container className="my-5">
      <Row>
        <Col md="6">
          <div className="Header__text pr-5 pl-3 pt-3">
            <h4>Missions du desk</h4>
            <p>
              Des bureaux d’information Europe Créative (Creative Europe Desks)
              ont été constitués dans tous les pays participants au programme.
            </p>
            <p>
              Ils ont pour mission d’informer les acteurs des secteurs culturels
              et créatifs (Culture, MEDIA) sur les possibilités offertes par le
              programme et de les accompagner dans leurs démarches.
            </p>
            <p>
              Le bureau Europe Créative en Tunisie joue le rôle d’interface
              entre l’Europe et la Tunisie. Il accompagne et informe sur le
              nouveau programme Europe Créative et sur les enjeux culturels de
              l’Union européenne.
            </p>
          </div>
        </Col>
        <Col md="6">
          <div className="Header__text pr-5 pl-3 pt-3">
            <p>
              Nos experts assurent en effet pour les acteurs culturels et
              audiovisuels de la Tunisie:
            </p>
            <p>
              La promotion des programmes communautaires ouverts à la culture et
              à l’audiovisuel, principalement Europe Créative.
            </p>
            <p>
              Une assistance et une aide technique dans la préparation de
              dossier de candidature.
            </p>
            <p>
              La mise en contact avec d’autres professionnels du secteur et avec
              des partenaires potentiels en nous appuyant sur le réseau des
              bureaux Europe Créative.
            </p>
            <p>La diffusion des résultats de sélection des appels à projets.</p>
            <p>
              L’organisation de séances d’information et de travail sur le
              programme Europe Créative et la coopération européenne.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default Presentation;
export const query = graphql`
  query {
    file(relativePath: { eq: "presentation.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
